.background_overlay {
	background: #585858cc;
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.add_singer_card {
		width: 50vw;
		background: #fff;
		padding: 60px;

		text-align: center;

		h4 {
			font-weight: 600;
			font-size: 30px;
			line-height: 190%;
			color: #203346;
		}

		p {
			line-height: 180.5%;
			margin-bottom: 30px;
		}

		.buttons_row {
			display: flex;
			justify-content: space-between;

			button {
				min-width: 30%;
			}
		}
	}
}

@include tablet-screen {
	.background_overlay {
		.add_singer_card {
			width: 90vw;
			padding: 30px;

			h4 {
				font-size: 25px;
			}

			p {
				font-size: 15px;
				margin-bottom: 20px;
			}
		}
	}
}
