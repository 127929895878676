.sidebar {
  background: #f2f3f7;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding-top: 100px;
  padding-left: 25px;
  display: flex;
  justify-content: start;
  align-items: start;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      padding: 20px 5px;
      margin: 10px 0px;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      cursor: pointer;
      &:hover {
        background-color: #a2daf7;
        border-radius: 5px;
      }
      .icon {
        font-size: 20px;
        margin-right: 20px;
        margin-top: -3px;
      }

      .active {
        color: #2a9bd4 !important;
      }

      .link {
        color: #203346;
      }
    }
  }
}

@include tablet-screen {
  .sidebar {
    display: none;
  }
}
