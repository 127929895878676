.verify_email_page {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	min-height: 100vh;

	.verify_email_card {
		width: 600px;
		background: #ffffff;
		box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);

		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 30px 20px;
		text-align: center;

		.logo {
			width: 280px;
			height: 70px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.icon_container {
			margin-top: 40px;
			.icon {
				font-size: 90px;
				color: #2a9bd4;
				font-weight: 900;
			}
		}

		p {
			margin-top: 40px;
			padding: 0 50px;
			color: #203346;
			opacity: 0.7;
			line-height: 190%;
		}

		.blue_button {
			width: 100%;
			button {
				width: 100%;
				border: 1px solid #d9d9d9;
				border-radius: 6px;
				height: 60px;
				cursor: pointer;
				background: #2a9bd4;
				border: none;
				outline: none;

				color: #fff;
			}
		}
	}
}

@include tablet-screen {
	.verify_email_page {
		width: 100%;
		min-height: 100vh;

		.verify_email_card {
			width: 90vw;

			padding: 20px 20px;

			.logo {
				width: 120px;
				height: 30px;
			}

			.icon_container {
				margin-top: 25px;
				.icon {
					font-size: 50px;
				}
			}

			p {
				margin-top: 25px;
				padding: 0 10px;
				font-size: 15px;
				line-height: 22px;
			}

			.blue_button {
				button {
					height: 40px;
				}
			}
		}
	}
}
