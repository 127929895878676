.folder_page {
  width: 100%;
  background-color: transparent; /* Used if the image is unavailable */
  background-size: 100% 100%;
  height: auto;
  .folder_content {
    width: 100%;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: transparent;

    .folder_name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #fff;
      margin: 15px;
      padding: 15px;
      h4 {
        margin-top: 10px;
      }
      .icon {
        cursor: pointer;
        margin-left: 20px;
      }
    }

    .lower {
      margin: 5px;
      padding: 5px;
      width: 100%;
      h5 {
        font-weight: 600;
        font-size: 22px;
        line-height: 190%;
        color: rgb(220, 227, 228);
      }

      .lower_card {
        box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
        background: #fff;
        width: 100%;
        padding: 10px;

        .table-responsive {
          overflow-x: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .table {
            width: 100%;
            text-align: center;
            thead {
              width: 100%;
              tr {
                border-bottom: 2px solid #c9c7c7;
              }
            }

            tbody {
              width: 100%;
              tr {
                margin: 5px;
                &:hover {
                  background-color: #0269a4;
                  color: #fff;
                  cursor: pointer;
                  &:hover a {
                    background-color: #0269a4;
                    color: #fff;
                    cursor: pointer;
                    text-decoration: underline;
                  }
                }

                td {
                  .icon {
                    color: #2a9bd4;
                  }
                  .icon_container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    .icon {
                      color: #2a9bd4;
                      font-size: 20px;
                      margin-top: 4px;
                      margin-left: 5px;
                    }
                    &:hover {
                      .icon {
                        color: white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include tablet-screen {
  .folder_page {
    .folder_content {
      .lower {
        .lower_card {
          .table-responsive {
            &::-webkit-scrollbar {
              display: block;
            }
            .table {
              margin: 0px;
              padding: 0px;
              font-size: smaller;
              font-weight: lighter;
              thead {
                tr {
                  border-bottom: 1px solid #c9c7c7;
                }
              }

              tbody {
                tr {
                  margin: 0px;
                  td {
                    .icon_container {
                      height: 10px;
                      .icon {
                        color: #2a9bd4;
                        font-size: 10px;
                        margin-left: 5px;
                      }
                      &:hover {
                        .icon {
                          color: white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
