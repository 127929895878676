.login_page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login_card {
    padding: 50px;
    width: 60vw;
    background: #ffffff;
    box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
    text-align: center;
    .error {
      color: #cc0000;
      font-size: medium;
      display: flex;
      align-self: left;
    }
    .logo {
      margin-bottom: 20px;
      img {
        width: 200px;
        height: 50px;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 30px;
      line-height: 190%;
      color: #203346;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 180.5%;
      color: #203346;
      opacity: 0.7;
      margin-bottom: 30px;
    }
  }
}

@include tablet-screen {
  .login_page {
    .login_card {
      padding: 40px;
      width: 90vw;
      text-align: left;

      .logo {
        margin-bottom: 10px;

        img {
          width: 128px;
          height: 32px;
        }
      }

      h4 {
        font-size: 22px;
        line-height: 33px;
      }

      p {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
  }
}
