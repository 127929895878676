.confirm_email {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	min-height: 100vh;

	.confirm_email_container {
		width: 600px;

		.confirm_email_card {
			background: #ffffff;
			box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);

			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 30px 30px;
			text-align: center;

			.logo {
				width: 280px;
				height: 70px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.mail_sent_img {
				margin-top: 40px;

				width: 130px;
				height: 120px;
				img {
					width: 100%;
					height: 100%;
				}
			}

			h4 {
				margin-top: 40px;
				font-weight: 600;
				font-size: 30px;
				color: #203346;
				padding: 0 50px;
			}

			p {
				padding: 0 50px;
				color: #203346;
				opacity: 0.7;
				font-size: 18px;
				line-height: 190%;
			}

			.blue_button {
				width: 100%;
				button {
					width: 100%;
					border: 1px solid #d9d9d9;
					border-radius: 6px;
					height: 60px;
					cursor: pointer;
					background: #2a9bd4;
					border: none;
					outline: none;

					color: #fff;
				}
			}
		}

		.below_card_text {
			padding: 50px 30px 0 30px;
			display: flex;
			justify-content: flex-start;
			gap: 20px;

			.circle_container {
				width: 30px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.circle {
					width: 20px;
					height: 20px;
					background: #a2daf7;
					border-radius: 50%;
				}
			}

			.text {
				font-size: 18px;
				color: #203346;
				opacity: 0.7;

				span {
					color: #2a9bd4;
					font-weight: bold;
				}
			}
		}
	}
}

@include tablet-screen {
	.confirm_email {
		.confirm_email_container {
			width: 90vw;

			.confirm_email_card {
				padding: 20px 20px;
				.logo {
					width: 120px;
					height: 30px;
				}

				.mail_sent_img {
					margin-top: 25px;

					width: 80px;
					height: 80px;
				}

				h4 {
					margin-top: 25px;
					font-size: 25px;
					padding: 0 10px;
				}

				p {
					padding: 0 10px;
					font-size: 15px;
					line-height: 22px;
				}

				.blue_button {
					button {
						height: 50px;
					}
				}
			}

			.below_card_text {
				padding: 25px 20px 0 20px;
				gap: 20px;

				.circle_container {
					width: 25px;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					.circle {
						width: 18px;
						height: 18px;
					}
				}

				.text {
					font-size: 15px;
				}
			}
		}
	}
}
