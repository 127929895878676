.plans_page {
  .page_content {
    padding: 130px 50px;

    h4 {
      font-weight: 600;
      font-size: 30px;
      line-height: 190%;
      color: #203346;
    }

    p {
      font-size: 18px;
      line-height: 180.5%;
      color: #203346;
      opacity: 0.7;
    }

    .text_card {
      background: #ffffff;
      border: 1px solid #ececec;
      box-sizing: border-box;
      box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      margin-top: 10px;
      padding: 20px 30px;

      h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #203346;
      }

      p {
        font-size: 18px;
        line-height: 180.5%;
        color: #203346;
        opacity: 0.7;
        margin-bottom: 0;

        span {
          font-weight: bold;
        }
      }
    }

    .plans_card {
      border: 1px solid #ececec;
      box-sizing: border-box;
      box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      margin-top: 20px;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      p.error {
        color: red;
        padding: 3px;
      }
      p.success {
        padding: 3px;
        color: green;
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: #203346;
        margin-bottom: 20px;

        span {
          color: #2a9bd4;
        }
      }

      p {
        line-height: 180.5%;
        margin-bottom: 0;

        span {
          font-weight: bold;
        }
      }
      .buy_credits {
        background-color: rgb(224, 228, 231);
        border-radius: 10px;
        border-color: #0a1a2b;
        border-style: solid;
        border-width: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 90%;

        .input_container {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          padding: 5px;
          margin: 5px;
          width: 90%;

          input {
            width: 70%;
            height: 30px;
            font-size: 14pt;
            border: solid 0.5px #000;
            z-index: 1;
          }

          .edbit_icon {
            width: 30%;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: center;
            padding: 2px;
            margin: 2px;
            .logo {
              width: 25px;
              height: 25px;
            }
            .text {
              font-weight: 600;
              font-size: large;
              font-family: Arial, Helvetica, sans-serif;
              color: #203346;
              padding-left: 10px;
            }
          }
        }
        .prices {
          p {
            font-size: 18px;
            line-height: 180.5%;
            color: #08121b;
            margin: 2px;
            padding: 2px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@include tablet-screen {
  .plans_page {
    .page_content {
      padding: 100px 15px 15px 20px;

      h4 {
        font-size: 25px;
      }

      p {
        font-size: 15px;
      }

      .text_card {
        margin-top: 10px;
        padding: 15px 15px;

        h5 {
          font-size: 16px;
        }

        p {
          font-size: 15px;
        }
      }

      .plans_card {
        margin-top: 10px;
        padding: 20px 5px;
        h4 {
          font-size: 18px;
        }

        p {
          font-size: 15px;
        }
      }
    }
  }
}
