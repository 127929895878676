.file_upload {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .button_container {
    width: 100%;
    margin: 5px 0;
    button {
      width: 100%;
      height: 60px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      color: #ffffff;
      background-color: #2a9bd4;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .responses {
    .success {
      color: green;
      margin: 0px;
    }
    .hide {
      visibility: hidden;
    }
    .error {
      color: red;
      margin: 0px;
    }
  }
  .loader_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    height: 110%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .space {
      z-index: 200;
      height: 30%;
      width: 100%;
    }
    .loader {
      display: flex;
      flex-direction: column;
      background-color: #2a9bd4;
      width: 60%;
      height: 210px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      z-index: 200;
    }
  }
  .file_input {
    position: relative;
    width: 100%;
    padding: 30px 40px;
    border: 1px dashed #2a9bd4;
    box-shadow: 0px 4px 57px rgba(0, 0, 0, 0.07);
    border-radius: 6px;

    input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;

      &::-webkit-file-upload-button {
        display: none;
      }
    }

    .input_placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: center;

      .img_container {
        img {
          width: 45px;
          height: 45px;
        }
      }

      h5 {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        line-height: 33px;
        color: #203346;
      }

      p {
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        color: #313131;
        opacity: 0.7;
        margin-bottom: 0;
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    li {
      font-weight: bold;
      font-size: 15px;
      margin: 5px;
    }
  }
}

@include tablet-screen {
  .file_upload {
    .loader_container {
      .space {
        height: 20%;
      }
    }
    .file_input {
      .input_placeholder {
        h5 {
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}
