.verifications_tools_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin-top: 60px;
  .verifications_tools_content {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 190%;
      text-align: center;
      color: #2a9bd4;

      .icon {
        margin-right: 20px;
        margin-top: -5px;
      }
    }

    .tx_hash {
      font-weight: 500;
      font-size: 20px;
      line-height: 190%;
      text-align: center;
      color: #2a9bd4;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 180.5%;
      text-align: center;
      color: #313131;
      opacity: 0.7 !important;
    }
    .result {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .input_container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    .powered_button {
      text-align: center;
      margin: 10px;

      button {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 6px;
        height: 70px;
        font-weight: 500;
        font-size: 16px;
        color: #313131;

        padding: 0 30px;

        img {
          width: 110px;
          height: 27px;
          margin-left: 30px;
        }
      }
    }

    .embed {
      width: 90%;
      min-height: 100px;
      padding: 10px;
      background-color: #e2e4e5;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 3px;
      h3 {
        font-weight: 600;
        font-size: medium;
        color: #212120;
      }

      .embed-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .text-area {
          width: 80%;
          padding: 5px;
          margin: 5px;
          block-size: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          textarea {
            padding: 5px;
            text-align: left;
            resize: none;
            width: 100%;
            border-radius: 5px;
            min-height: 120px;
            display: inline-block;
            overflow-wrap: break-word;
            background-color: #212120;
            color: #aaaaaa;
            white-space: pre-wrap;
            cursor: text;
            &:focus {
              outline: none;
            }
          }
        }
        .icon-container {
          padding: 5px;
          margin: 5px;
          width: 50px;
          height: 50px;
          border-radius: 5px;
          background-color: #aaaaaa;
          display: flex;
          justify-content: center;
          align-items: center;
          &:active {
            background-color: #212120;
            color: #aaaaaa;
            .popup-container {
              .popup {
                .icon {
                  color: #aaaaaa;
                }
              }
            }
          }

          .popup-container {
            text-align: center;
            .popup {
              position: relative;
              display: inline-block;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              span.show {
                visibility: visible;
                -webkit-animation: fadeIn 1s;
                animation: fadeIn 1s;
              }

              /* Add animation (fade in the popup) */
              @-webkit-keyframes fadeIn {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }

              @keyframes fadeIn {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
              .icon {
                font-size: medium;
                color: #212120;
              }
              .popup-text {
                visibility: hidden;
                width: 160px;
                background-color: #555;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 8px 0;
                position: absolute;
                z-index: 1;
                bottom: 150%;
                left: 50%;
                margin-left: -80px;
                &::after {
                  content: "";
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: #555 transparent transparent transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include tablet-screen {
  .verifications_tools_page {
    padding: 10px;
    .verifications_tools_content {
      width: 100%;

      h1 {
        font-size: 30px;
        text-align: left;

        .icon {
          margin-right: 10px;
          margin-top: -3px;
        }
      }

      p {
        font-size: 15px;
        text-align: left;
      }

      .input_container {
        margin-top: 20px;
      }

      .powered_button {
        text-align: center;
        // margin-top: 40px;

        button {
          height: 50px;
          font-size: 15px;

          padding: 0 20px;

          img {
            width: 80px;
            height: 20px;
            margin-left: 18px;
          }
        }
      }
      .embed {
        h3 {
          font-weight: 500;
          font-size: small;
          color: #212120;
        }
        // .embed-content {
        //   .text-area {
        //     textarea {
        //       // min-height: 180px;
        //     }
        //   }
        // }
      }
    }
  }
}
