.input_container {
	margin-bottom: 25px;
	width: 100%;

	input {
		width: 100%;
		height: 60px;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
		outline: none;
		padding-left: 30px;

		&::placeholder {
			color: #203346;
			opacity: 0.5;
			font-weight: 500;
			font-size: 18px;
			text-transform: capitalize;
		}
	}
}

@include tablet-screen {
	.input_container {
		margin-bottom: 25px;

		input {
			height: 50px;
			padding-left: 30px;

			&::placeholder {
				font-size: 16px;
			}
		}
	}
}
