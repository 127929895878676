.approve-documents-page {
	.approve-documents-page-container {
		width: 80%;
		display: flex;
		justify-content: center;
		margin: auto;
		padding: 0;
		margin-top: 150px;
		.title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.img-wrap-approval {
				width: 150px;
				height: 150px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			p {
				margin: 5px 0;
			}
		}

		.dropdown_container {
			right: 0;
		}

		.error {
			color: red;
		}

		.success {
			color: green;
		}

		.org_card {
			.org_form {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.approval-form {
					width: 70%;
				}

				p {
					width: 70%;
					font-size: 17px;
					font-style: italic;
					margin-bottom: 5px;
				}
			}
		}
	}
}
