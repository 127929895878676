.header2 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 90px;
	padding: 0 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.logo {
		cursor: pointer;
		img {
			width: 111px;
			height: 27px;
		}
	}

	.lg_screen_menu {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.preview {
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				background-color: white;
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
			.img-wrap {
				position: relative;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				height: inherit;
				width: inherit;
				border-radius: 50%;
				cursor: pointer;
			}

			// .user-approval-image {
			// }
			li {
				margin: 5px;
				border-radius: 7px;
				&:hover {
					background-color: #a2daf7;
				}
				font-size: 15px;
				color: #203346;
				padding: 13px 20px;
				cursor: pointer;

				.icon {
					font-size: 20px;
				}
			}
			.wallet {
				display: flex;
				gap: 20px;
				align-items: center;
				font-size: 15px;
				font-weight: 600;
				button {
					border: 2px solid rgb(231, 234, 243);
					color: #203346;
					border-radius: 12px;
					display: flex;
					align-items: center;
					height: 42px;
					font-weight: 600;
					font-family: Roboto, sans-serif;
					font-size: 14px;
					padding: 0px 10px;

					&:hover {
						background-color: #a2daf7;
					}
				}

				.credits_dropdown {
					position: relative;
					margin: 5px;
					padding: 0;
					&:hover {
						background-color: transparent;
						.credit_dropdown_card {
							visibility: visible;
							opacity: 1;
						}
						.disconnect {
							visibility: visible;
							opacity: 1;
						}
					}

					span {
						border: 2px solid rgb(231, 234, 243);
						color: #203346;
						border-radius: 12px;
						display: flex;
						align-items: center;
						justify-content: space-around;
						width: 150px;
						height: 42px;
						font-weight: 500;
						font-family: Roboto, sans-serif;
						font-size: 14px;
						padding: 0px 10px;
						&:hover {
							background-color: #a2daf7;
						}
						img {
							width: 25px;
							height: 25px;
						}
					}

					.credit_dropdown_card {
						display: flex;
						visibility: hidden;
						transition: opacity 0.5s ease, visibility 0.5s ease;
						opacity: 0;
						position: absolute;
						left: calc(50% - 200px);
						top: 50px;
						background-color: #f9f9f9;
						width: 400px;
						box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
						padding: 20px;
						z-index: 1;

						background: #ffffff;
						box-shadow: 6px 4px 80px rgba(0, 0, 0, 0.07);
						border-radius: 12px;
						justify-content: flex-start;
						gap: 20px;

						.img_container {
							img {
								width: 38px;
								height: 38px;
							}
						}

						.text_section {
							h5 {
								font-weight: 600;
								font-size: 18px;
								line-height: 160%;
								color: #313131;
							}

							p {
								font-size: 15px;
								line-height: 120%;
								color: #313131;
								margin-bottom: 0;
							}
						}
					}
					.disconnect {
						display: flex;
						visibility: hidden;
						transition: opacity 0.5s ease, visibility 0.5s ease;
						opacity: 0;
						position: absolute;
						left: calc(50% - 70px);
						top: 50px;
						width: fit-content;
						height: 40px;
						padding: 10px;
						z-index: 1;

						background-color: #ffffff;
						box-shadow: 6px 4px 80px rgba(0, 0, 0, 0.07);
						align-items: center;

						border: 2px solid rgb(231, 234, 243);
						color: #203346;
						border-radius: 12px;

						h5 {
							font-weight: 600;
							font-family: Roboto, sans-serif;
							font-size: 14px;
						}
						&:hover {
							background-color: #a2daf7;
							cursor: pointer;
						}
					}
				}
			}

			li.setting_menu {
				position: relative;
				.hide {
					display: none;
				}
				.show {
					display: block;
				}

				.number-of-waiting-approvals {
					width: 25px;
					// border: 1px solid;
					border-radius: 50%;
					height: 25px;
					position: absolute;
					color: white;
					left: 0;
					background: #2a9bd4;
					text-align: center;
				}
				.dropdown_container {
					position: absolute;

					padding-top: 20px;

					z-index: 9;
					left: 0;

					.setting_menu_dropdown {
						width: 200px;
						padding: 15px 10px;
						background: #fff;
						box-shadow: 6px 4px 80px rgba(0, 0, 0, 0.301);
						border-radius: 6px;

						ul {
							margin: 0;
							padding: 0;
							list-style: none;
							display: flex;
							flex-direction: column;
							li,
							.link {
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding: 10px 30px;
								margin: 10px 30px;
								cursor: pointer;

								&:hover {
									background-color: #a2daf7;
									border-radius: 6px;
								}

								.text {
									color: #203346;
									opacity: 0.7;
									font-size: 16px;
								}

								.icon_container {
									.icon {
										font-size: 22px;
										color: #203346;
										opacity: 0.7;
									}
								}
							}

							li.active {
								.text {
									color: #2a9bd4;
									opacity: 0.9;
									font-size: 18px;
									font-weight: bold;
								}

								.icon_container {
									.icon {
										font-size: 22px;
										color: #2a9bd4;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.mobile_menu {
		display: none;
	}

	.hamburger_menu {
		display: none;
	}

	.mobile_menu {
		position: absolute;
		align-items: center;
		justify-content: center;
		right: 20px;
		top: 70px;
		background: #ffffff;
		box-shadow: 6px 4px 80px 80px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		padding: 10px;
		width: 60%;
		z-index: 999;

		ul {
			list-style: none;
			padding: 5px;
			margin: 5px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			li.profile {
				padding: 10px;
			}
			.wallet {
				display: flex;
				flex-direction: column;
				gap: 15px;
				align-items: center;
				font-size: 15px;
				font-weight: 600;
				button {
					border: 2px solid rgb(231, 234, 243);
					color: #203346;
					border-radius: 12px;
					display: flex;
					align-items: center;
					height: 42px;
					font-weight: 600;
					font-family: Roboto, sans-serif;
					font-size: 14px;
					padding: 0px 10px;

					&:hover {
						background-color: #a2daf7;
					}
				}

				.credits_dropdown {
					position: relative;
					margin: 5px;
					padding: 0;
					&:hover {
						.credit_dropdown_card {
							visibility: visible;
							opacity: 1;
						}
					}

					span {
						border: 2px solid rgb(231, 234, 243);
						color: #203346;
						border-radius: 12px;
						display: flex;
						align-items: center;
						justify-content: space-around;
						width: 150px;
						height: 42px;
						font-weight: 500;
						font-family: Roboto, sans-serif;
						font-size: 14px;
						padding: 0px 10px;
						&:hover {
							background-color: #a2daf7;
						}
						img {
							width: 25px;
							height: 25px;
						}
					}

					.credit_dropdown_card {
						display: flex;
						visibility: hidden;
						transition: opacity 0.5s ease, visibility 0.5s ease;
						opacity: 0;
						position: absolute;
						left: calc(50% - 100px);
						top: 50px;
						width: 225px;
						height: 150px;
						box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
						padding: 20px;
						z-index: 1;

						background-color: #ffffff;
						box-shadow: 6px 4px 80px rgba(0, 0, 0, 0.07);
						border-radius: 10px;
						justify-content: flex-start;
						align-items: center;
						gap: 10px;

						.img_container {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: space-evenly;
							align-items: center;
							height: 100%;
							width: 100%;
							img {
								width: 25px;
								height: 25px;
							}
						}

						.text_section {
							flex: 3;
							height: 100%;
							width: 100%;
							display: flex;
							flex-direction: column;
							justify-content: space-evenly;
							align-items: center;
							h5 {
								font-weight: 600;
								font-size: 16px;
								line-height: 160%;
								color: #313131;
							}
						}
					}
				}
			}
			.profile {
				// background-color: #a2daf7;
				border-radius: 5px;
				padding: 5px 5px;
				.preview {
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					background-color: white;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					margin-right: 10px;
				}
				.img-wrap {
					position: relative;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					height: inherit;
					width: inherit;
					border-radius: 50%;
				}
			}
			li {
				color: #203346;
				border-radius: 5px;
				padding: 5px 5px;
				text-align: center;
				width: 100%;
				&:focus,
				&:active {
					background-color: #74b0ec;
					border-radius: 5px;
					padding: 5px 5px;
				}
			}
		}
	}
}

@include desktop-screen {
	.header2 {
		padding: 0 60px;
	}
}

@include laptop-screen {
	.header2 {
		padding: 0 30px;
	}
}

@include tablet-screen {
	.header2 {
		height: 80px;
		padding: 0 20px;

		.logo {
			img {
				width: 128px;
				height: 32px;
			}
		}

		.lg_screen_menu {
			display: none;
		}

		.mobile_menu {
			display: flex;
		}

		.hamburger_menu {
			display: block;

			.icon {
				font-size: 22px;
			}
		}
	}
}
