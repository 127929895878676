.page_10 {
	margin: 0 100px;

	.page_content {
		margin: 40px 0;

		h5 {
			color: #203346;
			font-weight: 600;
			font-size: 20px;
			padding-bottom: 15px;
		}

		p {
			font-weight: normal;
			margin: 0;
		}

		.folder_name {
			margin: 10px 0;

			border-radius: 6px;
			max-width: 400px;
			height: 55px;

			display: flex;
			align-items: center;

			input {
				box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
				border: 1px solid #d9d6d6;
				width: 100%;
				height: 100%;
				outline: none;
				opacity: 1;
				color: #203346;
				padding-left: 20px;

				&::placeholder {
					color: #203346;
				}
			}
		}

		.document_heading {
			margin: 40px 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 40px;

			h5 {
				margin: 0;
				padding: 0;
			}

			.document_input {
				border: 1px solid #2a9bd4;
				box-sizing: border-box;
				box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
				border-radius: 6px;
				max-width: 450px;
				height: 55px;
				display: flex;
				gap: 20px;
				align-items: center;
				padding: 0 20px;

				font-weight: normal;
				font-size: 16px;
				color: #203346;

				.icon {
					color: #2a9bd4;
					font-size: 22px;
				}
			}
		}

		.document_input {
			margin: 20px 0;
			background: #ffffff;
			border: 1px solid #2a9bd4;
			box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
			border-radius: 6px;
			max-width: 400px;
			height: 55px;

			display: flex;
			align-items: center;
			padding-left: 20px;

			img {
				width: 23px;
				height: 25px;
				margin-right: 20px;
			}
		}
	}
}

@include tablet-screen {
	.page_10 {
		margin: 0 20px;

		.page_content {
			margin: 20px 0;

			h5 {
				font-size: 18px;
				padding-bottom: 10px;
			}

			p {
				font-size: 14px;
			}

			.folder_name {
				margin: 7px 0;
				height: 45px;

				padding-left: 10px;
			}

			.document_heading {
				margin: 40px 0 0 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: 1px;

				.document_input {
					max-width: 450px;
					width: 100%;
					height: 55px;
					display: flex;
					gap: 20px;
					align-items: center;
					padding: 0 20px;

					font-weight: normal;
					font-size: 16px;
					color: #203346;

					.icon {
						color: #2a9bd4;
						font-size: 22px;
					}
				}
			}

			.input_container {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			.document_input {
				margin: 20px 0;
				background: #ffffff;
				border: 1px solid #2a9bd4;
				box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
				border-radius: 6px;
				max-width: 400px;
				height: 55px;

				display: flex;
				align-items: center;
				padding-left: 20px;

				img {
					width: 23px;
					height: 25px;
					margin-right: 20px;
				}
			}
		}
	}
}
