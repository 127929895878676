.result_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 10px;
  margin: 20px;
  border-radius: 15px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  background-color: #fafafa;
  width: 90%;
  overflow-wrap: break-word;
  word-break: break-word;
  .file_name {
    color: #8a909e;
    flex: 1;
    display: inline-block;
    word-wrap: break-word;
  }
  .error_title p {
    color: red;
    padding: 6px;
    width: 100%;
    display: inline-block;
    word-wrap: break-word;
  }
  .details_drop_down {
    background-color: #ccffcc;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    .details_row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .green {
        color: green;
      }
      .wrap_text {
        color: green;
        height: 95%;
        width: 70%;
      }
      .details_drop_down {
        background-color: green;
        color: white;
        width: 100px;
        height: 80%;
        border-radius: 7.5px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }
    .collapsible .content {
      height: 0px;
      overflow: hidden;
      transition: height width ease 0.5s;
    }
    .collapsible .show {
      height: 100%;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      justify-self: center;
      padding: 5px;
      border-radius: 15px;
      .details_row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 90%;
        margin: 5px;
        .green {
          color: green;
          margin-right: 30px;
        }
        .wrap_text {
          position: relative;
          top: 5px;
          color: green;
          height: 90%;
          width: 90%;
        }
      }
    }
  }

  .issuer_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 10px;
    width: 95%;
    .text {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      width: 100%;
      h6 {
        font-weight: 600;
        color: #8a909e;
      }
    }

    .issuer_column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
      background-color: #fafafa;
      border-radius: 10px;
      width: 80%;
      .name_icon {
        color: #0768f2;
      }

      .issuer_drop_down {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin: 5px;
        padding: 5px;

        div span {
          margin: 10px;
        }
        .file-hash {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          overflow-wrap: break-word;
          word-break: break-word;
          p,
          a {
            flex: 1;
            display: inline-block;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

@include tablet_screen {
  // .result_container {
  //   .issuer_details {
  //     .issuer_column {
  //       .issuer_drop_down {
  //         div p,
  //         div a {
  //           // padding: 6px;
  //           width: 150px;
  //           inline-size: 200px;
  //           display: inline-block;
  //           word-wrap: break-word;
  //         }
  //         // div a {
  //         //   padding: 6px;
  //         //   width: 10px;
  //         //   display: inline-block;
  //         //   word-wrap: break-word;
  //         // }
  //       }
  //     }
  //   }
  // }
}
