.settings_menu {
	margin: 50px;
	padding: 15px 0;
	background: #ffffff;
	box-shadow: 6px 4px 80px rgba(0, 0, 0, 0.07);
	border-radius: 6px;

	max-width: 300px;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 30px;
			cursor: pointer;

			.text {
				color: #203346;
				opacity: 0.7;
				font-size: 16px;
			}

			.icon_container {
				.icon {
					font-size: 22px;
					color: #203346;
					opacity: 0.7;
				}
			}
		}

		li.active {
			.text {
				color: #2a9bd4;
				opacity: 0.9;
				font-size: 18px;
				font-weight: bold;
			}

			.icon_container {
				.icon {
					font-size: 22px;
					color: #2a9bd4;
				}
			}
		}
	}
}

@include tablet-screen {
	.settings_menu {
		margin: 20px;
		padding: 15px 0;
		max-width: 300px;

		ul {
			li {
				padding: 7px 20px;

				.text {
					font-size: 15px;
				}

				.icon_container {
					.icon {
						font-size: 22px;
					}
				}
			}

			li.active {
				.text {
					font-size: 16px;
				}

				.icon_container {
					.icon {
						font-size: 22px;
					}
				}
			}
		}
	}
}
