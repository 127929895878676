.signup_page {
  padding: 100px 50px 80px 50px;
  background: #fff;
  position: relative;
  
  .text_section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .img_container {
      width: 45%;
      height: 350px;
      position: absolute;
      top: 100px;
      left: 5%;
      img {
        width: 90%;
        height: 350px;
        object-fit: contain;
      }
    }

    .points {
      width: 100%;
      position: relative;
      top: 400px;
      left: 0%;
      .point {
        display: flex;
        gap: 25px;
        justify-content: flex-start;

        .icon_container {
          .icon {
            font-size: 25px;
            color: #2a9bd4;
          }
        }

        .text {
          h5 {
            font-weight: 600;
            font-size: 20px;
            color: #313131;
            opacity: 0.8;
          }

          p {
            font-size: 16px;
            line-height: 27px;
            color: #313131;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .signup_card {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .signup_form {
      width: 100%;
    .error {
      color: #cc0000;
      font-size: medium;
    }
    .success {
      color: #50c878;
      font-size: medium;
    }
  }
    .verify_box {
      display: flex;
      height: 100%;
      width: 90%;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .yellow_box {
        display: flex;
        background-color: #fff3cc;
        border-radius: 5px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        p {
          color: #cb9a03;
          padding: 10px;
          margin: 10px;
        }
      }
      p {
        color: #203346;
        padding: 5px;
        margin: 5px;
      }
      .responses {
        padding-bottom: 100px;
        .error {
          color: #cc0000;
          font-size: medium;
        }
        .success {
          color: #50c878;
          font-size: medium;
        }
      }
    }
    h1 {
      font-weight: 600;
      font-size: 50px;
      line-height: 75px;
      color: #203346;
    }

    h6 {
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #203346;
      opacity: 0.8;
    }

    .input_row {
      display: flex;
      margin-top: 40px;
      gap: 20px;
      justify-content: space-between;
    }

    .checkbox_row {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      align-items: center;

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        border: 1px solid #d9d9d9;
      }

      label {
        color: #203346;
        opacity: 0.7;
      }
    }

    .button_container {
      width: 100%;
      margin: 20px 0;

      button {
        width: 100%;
        height: 60px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        color: #ffffff;
        font-weight: 500;
        font-size: 18px;
      }
    }
    .verification_link {
      width: 100%;
      margin: 40px 0;

      p {
        width: 100%;
        height: 60px;
        font-size: 16px;
        line-height: 27px;
        color: #313131;
        opacity: 0.5;
      }
      span {
        color: #55afdd;
      }
      span:hover {
        cursor: pointer;
      }
    }

    .transparent_button {
      button {
        background: transparent;
        border: 1px solid #d9d9d9;
        color: #203346;
        opacity: 0.8;

        img {
          margin-right: 20px;
        }
      }
    }

    .blue_button {
      button {
        background: #2a9bd4;
      }
    }

    .login_link_container {
      font-size: 18px;
      color: #203346;
      opacity: 0.8;
      cursor: pointer;

      span {
        color: #2a9bd4;
        // padding-left: 15px;
      }
    }

    .or_section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;

      .hr_break_line {
        width: 35%;
        height: 1px;
        background-color: #d9d9d9;
      }

      .or {
        font-weight: bold;
      }
    }
  }

  .logo {
    position: absolute;
    top: 30px;
    width: 200px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@include tablet-screen {
  .signup_page {
    padding: 90px 20px 50px 20px;

    .text_section {
      .img_container {
        display: none;
      }

      .points {
        position: static;
        margin-top: 10px;
        .point {
          gap: 15px;

          .icon_container {
            .icon {
              margin-top: -5px;
              font-size: 18px;
              color: #2a9bd4;
            }
          }

          .text {
            h5 {
              font-size: 16px;
            }

            p {
              font-size: 15px;
              line-height: 22px;
            }
          }
        }
      }
    }

    .signup_card {
      padding: 40px 20px;
      height: fit-content;
      .verify_box {
        height: fit-content;

        .responses {
          padding: 0px;
        }
      }
      h1 {
        font-size: 30px;
        line-height: 45px;
      }

      h6 {
        font-size: 15px;
        line-height: 22px;
      }

      .input_row {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        gap: 0;
      }

      .checkbox_row {
        gap: 15px;

        input[type="checkbox"] {
          width: 16px;
          height: 16px;
        }

        label {
          font-size: 14px;
        }
      }

      .button_container {
        margin: 25px 0;

        button {
          height: 60px;
          font-size: 16px;
        }
      }

      .transparent_button {
        button {
          img {
            margin-right: 15px;
          }
        }
      }

      .login_link_container {
        font-size: 16px;

        span {
          padding-left: 10px;
        }
      }

      .or_section {
        margin-top: 25px;
      }
    }

    .logo {
      top: 30px;
      width: 120px;
      height: 30px;
    }
  }
}
