.certify_doc_comp {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 75px;
	.content_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.upper_card {
			width: 100%;
			padding: 30px 50px 0 50px;
			background: #ffffff;
			box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
			.certify_row {
				margin-top: 30px;
				display: flex;
				gap: 15px;
				justify-content: center;

				.text {
					h5 {
						margin: 4px 0 0 0;
						text-align: left;
						font-weight: bold;
						font-size: 18px;
						line-height: 27px;
						color: #203346;
					}
					p {
						margin-top: 5px;
						line-height: 27px;
						opacity: 0.8;
					}
				}
			}

			h4 {
				font-weight: 600;
				font-size: 25px;
				color: #2a9bd4;
			}

			.input_container {
				margin-top: 20px;
				display: flex;
				justify-content: center;
			}
		}
		.page_content {
			margin: 10px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			h5 {
				color: #203346;
				font-weight: 600;
				font-size: 20px;
				padding-bottom: 10px;
			}

			p {
				font-weight: normal;
				margin: 0;
			}
			.input_row {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				margin: 5px 5px;
				.folder_name {
					border-radius: 6px;
					max-width: 400px;
					height: fit-content;
					display: flex;
					align-items: center;
					flex-direction: column;

					.button_container {
						width: 100%;
						button {
							width: 100%;
							height: 60px;
							border: 1px solid #d9d9d9;
							border-radius: 6px;
							color: #ffffff;
							background-color: #2a9bd4;
							font-weight: 500;
							font-size: 18px;
						}
					}
					h6 {
						color: #203346;
						font-weight: 600;
						margin: 5px;
						padding: 5px;
					}
					form-control-lg,
					form-select-lg {
						width: 100%;
						height: 50px;
						border: 1px solid #d9d9d9;
						border-radius: 6px;
						outline: none;
						padding: 10px 30px;
						margin: 5px;
						color: rgba(32, 51, 70, 0.8);
						font-weight: 500;
						font-size: 18px;

						// text-transform: capitalize;

						option {
							color: rgba(32, 51, 70, 0.5);
							border-radius: 5px;
						}

						&:focus {
							color: rgba(32, 51, 70, 1);
							box-shadow: 0 0 5px rgb(2, 120, 153);
							border: 1px solid rgba(2, 120, 153, 1);
						}

						&::placeholder {
							color: rgba(32, 51, 70, 0.5);
						}
					}
				}
			}

			.approval-checkbox {
				display: flex;
				align-items: start;
				justify-content: space-around;
				width: 50%;
			}
			.document_heading {
				margin: 40px 0;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 40px;

				h5 {
					margin: 0;
					padding: 0;
				}

				.document_input {
					border: 1px solid #2a9bd4;
					box-sizing: border-box;
					box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
					border-radius: 6px;
					max-width: 450px;
					height: 55px;
					display: flex;
					gap: 20px;
					align-items: center;
					padding: 0 20px;

					font-weight: normal;
					font-size: 16px;
					color: #203346;

					.icon {
						color: #2a9bd4;
						font-size: 22px;
					}
				}
			}

			.document_input {
				margin: 20px 0;
				background: #ffffff;
				border: 1px solid #2a9bd4;
				box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
				border-radius: 6px;
				max-width: 400px;
				height: 55px;

				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 23px;
					height: 25px;
					margin-right: 20px;
				}
			}
		}
		.blue_card {
			text-align: left;
			margin: 20px 0 0 0;
			gap: 20px;
			display: flex;
			justify-content: flex-start;
			background: #2a9bd4;
			border-radius: 6px;
			padding: 20px;

			.text {
				h5 {
					color: #fff;
					font-size: 20px;
					font-weight: bold;
				}

				p {
					font-size: 15px;
					font-weight: 500;
					color: #fff;
				}
			}
		}
	}
}

@include tablet-screen {
	.certify_doc_comp {
		text-align: left;
		margin-top: 10px;
		.content_container {
			width: 100%;

			.upper_card {
				padding: 20px 20px 0 20px;

				.logo {
					img {
						width: 100px;
						height: 125px;
					}
				}

				.welcome_text {
					margin-top: 15px;
					font-size: 15px;
				}

				h4 {
					font-size: 22px;
				}

				.certify_row {
					margin-top: 20px;
					gap: 10px;
					justify-content: flex-start;

					.text {
						h5 {
							margin: 2px 0 0 0;
							font-size: 16px;
							line-height: 25px;
						}
						p {
							margin-top: 4px;
							font-size: 15px;
							line-height: 22px;
						}
					}
				}

				.input_container {
					margin-top: 15px;
				}
			}
			.page_content {
				.input_row {
					flex-wrap: wrap;
				}
			}
			.blue_card {
				text-align: left;
				margin: 20px 0 0 0;
				gap: 15px;
				padding: 15px;

				.text {
					h5 {
						font-size: 18px;
					}

					p {
						font-size: 15px;
					}
				}
			}
		}
	}
}
