.page_12 {
	margin: 0 100px;

	.page_12_container {
		height: calc(100vh - 100px);

		display: flex;
		justify-content: center;
		align-items: center;

		.page_12_content {
			text-align: center;
			max-width: 600px;
			height: auto;

			.icon {
				font-size: 70px;
				color: #2a9bd4;
			}

			h2 {
				margin-top: 15px;
				font-weight: 600;
				font-size: 40px;
				line-height: 190%;
				color: #203346;
			}

			p {
				line-height: 180.5%;
			}

			.buttons_row {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
			}

			.powered_button {
				text-align: center;
				margin-top: 80px;

				button {
					background: #ffffff;
					border: 1px solid #d9d9d9;
					box-sizing: border-box;
					border-radius: 6px;
					height: 70px;
					font-weight: 500;
					font-size: 16px;
					color: #313131;
					// opacity: 0.5;

					padding: 0 30px;

					img {
						width: 110px;
						height: 27px;
						margin-left: 30px;
					}
				}
			}
		}
	}
}

@include tablet-screen {
	.page_12 {
		margin: 0 20px;

		.page_12_container {
			height: calc(100vh - 100px);

			.page_12_content {
				max-width: 600px;

				.icon {
					font-size: 70px;
				}

				h2 {
					margin-top: 15px;
					font-weight: 600;
					font-size: 40px;
					line-height: 190%;
					color: #203346;
				}

				p {
					font-size: 18px;
					line-height: 180.5%;
					color: #203346;
					opacity: 0.7;
				}

				.buttons_row {
					margin-top: 30px;
					display: flex;
					justify-content: space-between;
				}

				.powered_btn {
					margin-top: 80px;

					img {
						width: 108px;
						height: 28px;
						margin-left: 20px;
					}
				}
			}
		}
	}
}
