.profile_page {
  .page_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 130px 25px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .space {
        width: 50%;
        height: 30px;
      }
      h2 {
        font-weight: 600;
        font-size: 30px;
        line-height: 190%;
        color: #203346;
      }
      
    }

    .img-wrap {
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      width: inherit;
      border-radius: 50%;
      cursor: pointer;
      &:hover .img-text {
        visibility: visible;
        opacity: 1;
      }
      .img-text {
        &:hover {
          visibility: visible;
          opacity: 1;
        }
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s, visibility 0.2s;
        font-size: 20px;
        font-weight: bold;
        text-shadow: 1px 1px #fff;
        text-decoration-color: #000;
        text-decoration: underline;
        color: #000;
      }
      #preview,
      #img-uploader {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: white;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        box-shadow: 5px 5px 5px 5px lightgray;
        cursor: pointer;
      }
    }
    .button_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin: 25px;
      button {
        width: 100%;
        height: 60px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        color: #ffffff;
        background-color: #2a9bd4;
        font-weight: 500;
        font-size: 18px;
      }
    }
    .success {
      color: green;
    }
    .error {
      color: red;
    }
    h2 {
      font-weight: 600;
      font-size: 30px;
      line-height: 190%;
      color: #203346;
    }
    h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 190%;
        padding: 5px;
        margin: 5px;
      }
    button {
      float: right;
      padding: 0 30px;

      .icon {
        margin-right: 15px;
        font-size: 25px;
      }
    }

    .update_name_form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .form_card {
        padding: 25px;
        width: 100%;
        background: #ffffff;
        box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .error {
          color: #cc0000;
          font-size: medium;
        }
        .success {
          color: green;
          font-size: medium;
        }
        .logo {
          margin-bottom: 20px;
          img {
            width: 200px;
            height: 50px;
          }
        }

        h4 {
          font-weight: 600;
          font-size: 30px;
          line-height: 190%;
          color: #203346;
        }

        p {
          font-weight: normal;
          font-size: 18px;
          line-height: 180.5%;
          color: #203346;
          opacity: 0.7;
          margin-bottom: 30px;
        }
        .button_container {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          width: 100%;
          margin: 25px;
          button {
            width: 100%;
            height: 60px;
            border: 1px solid #d9d9d9;
            border-radius: 6px;
            color: #ffffff;
            background-color: #2a9bd4;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }
  }
}
@include tablet-screen {
  .keys_page {
    .page_content {
      padding: 60px 5px;

      h2 {
        font-size: 25px;
      }
      h3 {
        font-size: 15px;
      }
      button {
        padding: 0 20px;

        .icon {
          font-size: 22px;
        }
      }

      .key_card {
        margin-top: 100px;
        height: 80px;
        padding: 30px;

        .name,
        .date {
          font-weight: 600;
          font-size: 18px;
        }

        .icon {
          font-size: 25px;
        }
      }
    }
  }
}
