.modal {
  h4 {
    margin: 4px;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #203346;
  }
  .error {
    color: red;
  }
}
.overview {
  position: relative;
  width: 100%;
  .overview_content {
    width: 100%;
    // min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .lower {
      padding: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .new_folder_container {
        margin-top: 75px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        div {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          width: 120px;
          height: fit-content;
          h6 {
            margin-top: 8px;
            font-weight: 600;
            font-size: 15px;
            color: #5f7dc2;
          }
        }
        h5 {
          font-weight: 600;
          font-size: 22px;
          color: #203346;
        }
      }

      .lower_card {
        box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
        background: #fff;
        width: 100%;
        // padding: 10px;
        margin: 5px;
        overflow-wrap: break-word;
        word-break: break-word;
        .tab {
          overflow: hidden;
          border: 1px solid #ccc;
          background-color: #f1f1f1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          .tablinks {
            background-color: #f1f1f1;
            flex: 1;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 5px 0px;
            height: 100%;
            transition: 0.3s;
            &.active {
              background-color: #2a9bd4;
            }
            &:hover {
              background-color: #a2d9f7;
            }
          }
        }

        .table-responsive {
          overflow-x: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .table {
            width: 100%;
            text-align: center;
            table-layout: fixed;
            thead {
              width: 100%;
              tr {
                border-bottom: 2px solid #c9c7c7;
              }
            }

            tbody {
              width: 100%;
              tr {
                margin: 5px;
                &:hover {
                  background-color: #0269a4;
                  color: #fff;
                  cursor: pointer;
                  &:hover a {
                    background-color: #0269a4;
                    color: #fff;
                    cursor: pointer;
                    text-decoration: underline;
                  }
                }

                td {
                  .icon {
                    color: #2a9bd4;
                  }
                  .icon_container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    .icon {
                      color: #2a9bd4;
                      font-size: 20px;
                      margin-top: -4px;
                      margin-left: 5px;
                    }
                    &:hover {
                      .icon {
                        color: white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .upper_card {
      width: 100%;
      // background: #ffffff;
      box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
      padding: 40px;

      .certify_row {
        display: flex;
        gap: 15px;
        justify-content: center;

        .text {
          h5 {
            margin: 4px 0 0 0;
            text-align: left;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            color: #203346;
          }
          p {
            margin-top: 5px;
            line-height: 27px;
          }
        }
      }

      .input_container {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@include tablet-screen {
  .overview {
    .overview_content {
      .upper_card {
        width: 100%;
        padding: 20px;

        .certify_row {
          gap: 10px;
          .text {
            h5 {
              margin: 4px 0 0 0;
              font-size: 18px;
            }
            p {
              font-size: 15px;
            }
          }
        }

        .input_container {
          margin-top: 15px;
        }
      }

      .lower {
        // width: 90vw;
        margin-top: 40px;

        h5 {
          font-size: 20px;
        }
        .new_folder_container {
          margin-top: 10px;
        }
        .lower_card {
          .table-responsive {
            &::-webkit-scrollbar {
              display: block;
            }
            .table {
              margin: 0px;
              padding: 0px;
              font-size: smaller;
              font-weight: lighter;
              thead {
                tr {
                  border-bottom: 1px solid #c9c7c7;
                }
              }

              tbody {
                tr {
                  margin: 0px;
                  td {
                    .icon_container {
                      height: 10px;
                      .icon {
                        color: #2a9bd4;
                        font-size: 10px;
                        margin-left: 5px;
                      }
                      &:hover {
                        .icon {
                          color: white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
