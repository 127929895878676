.app_page {
	.page_content {
		padding: 120px 50px 50px 40px;

		h3 {
			font-weight: 600;
			font-size: 30px;
			line-height: 190%;
			color: #203346;
		}

		p {
			line-height: 180.5%;
		}

		.big_card {
			background: #ffffff;
			border: 1px solid #ececec;
			box-sizing: border-box;
			box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
			border-radius: 6px;
			padding: 20px;

			h4 {
				font-weight: 600;
				font-size: 22px;
				line-height: 33px;
				color: #203346;
			}
			p {
				line-height: 27px;
			}

			input {
				width: 25px;
				height: 25px;
			}

			label {
				font-size: 16px;
				font-weight: 600;
				margin-top: 5px;
				margin-left: 20px;
				color: #203346;
			}

			.left {
				input {
					width: 50px;
					height: 25px;
				}
			}

			.first_card,
			.second_card {
				padding: 20px;
				background: #ffffff;
				border: 1px solid #ececec;
				box-sizing: border-box;
				box-shadow: 6px 4px 28px rgba(0, 0, 0, 0.06);
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 40px;
			}

			.third_section {
				.content {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.left {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 30px;

						h5 {
							font-weight: 600;
							font-size: 18px;
							line-height: 27px;
							color: #203346;
						}
						p {
							margin: 0;
							line-height: 27px;
						}
					}
				}
			}
		}
	}
}

@include tablet-screen {
	.app_page {
		.page_content {
			padding: 100px 20px 20px 20px;

			h3 {
				font-size: 20px;
			}

			p {
				font-size: 14px;
			}

			.big_card {
				padding: 0;

				h4 {
					font-size: 18px;
				}
				p {
					font-size: 14px;
				}

				input {
					width: 18px;
					height: 18px;
				}

				label {
					font-size: 14px;
					margin-top: 4px;
					margin-left: 10px;
				}

				.left {
					input {
						width: 40px;
						height: 20px;
					}
				}

				.first_card,
				.second_card {
					padding: 15px;
					gap: 20px;
				}

				.third_section {
					padding: 20px;
					.content {
						.left {
							gap: 20px;

							h5 {
								font-size: 14px;
							}
							p {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}
