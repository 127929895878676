* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: $poppins, sans-serif;
}

.link {
	text-decoration: none;
}

input.form-control-lg,
select.form-select-lg {
	width: 100%;
	height: 60px;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	outline: none;
	padding: 0 30px;
	color: rgba(32, 51, 70, 0.5);
	font-weight: 500;
	font-size: 18px;
	// text-transform: capitalize;

	option {
		color: rgba(32, 51, 70, 0.5);
	}

	&:focus {
		color: rgba(32, 51, 70, 0.8);
	}

	&::placeholder {
		color: rgba(32, 51, 70, 0.5);
	}
}

.btn-primary {
	background: #2a9bd4;
	border: 1px solid #2a9bd4;
	border-radius: 6px;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	height: 60px;

	&:hover {
		background: #339ed3;
	}
}

.btn-outline {
	height: 60px;
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	border-radius: 6px;
	color: #2a9bd4;
	font-weight: 500;
	font-size: 18px;
	padding: 0 30px;

	&:hover {
		background: #2a9bd4;
		color: #fff;
	}
}

.row {
	margin: 0;
}
.col {
	padding: 0;
}

p {
	opacity: 0.6 !important;
	color: #203346;
	font-size: 18px;
}

@include tablet-screen {
	input.form-control-lg,
	select.form-select-lg {
		height: 50px;
		padding: 0 20px;

		&::placeholder {
			font-size: 16px;
		}
	}

	.btn-primary {
		font-size: 16px;
		height: 50px;
	}

	.btn-outline {
		height: 50px;
		font-size: 16px;
		padding: 0 20px;
	}

	p {
		font-size: 15px;
	}
}
