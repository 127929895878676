.organization_page {
  .page_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 130px 25px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .space {
        width: 50%;
        height: 30px;
      }
      h2 {
        font-weight: 600;
        font-size: 30px;
        line-height: 190%;
        color: #203346;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 30px;
      color: #203346;
      margin: 10px;
    }

    .org_card {
      padding: 50px;
      width: 100%;
      background: #ffffff;
      box-shadow: 12px 4px 165px rgba(0, 0, 0, 0.08);
      text-align: center;

      p {
        line-height: 180.5%;
        margin-bottom: 30px;
      }

      .org_form {
        .error {
          color: red;
        }
        .success {
          color: green;
        }
        .org_buttons_row {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
@include tablet-screen {
  .keys_page {
    .page_content {
      padding: 60px 5px;

      h2 {
        font-size: 25px;
      }
      button {
        padding: 0 20px;

        .icon {
          font-size: 22px;
        }
      }

      .key_card {
        margin-top: 100px;
        height: 80px;
        padding: 30px;

        .name,
        .date {
          font-weight: 600;
          font-size: 18px;
        }

        .icon {
          font-size: 25px;
        }
      }
    }
  }
}

.button_container {
  width: 100%;
  button {
    width: 100%;
    height: 60px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    color: #ffffff;
    background-color: #2a9bd4;
    font-weight: 500;
    font-size: 18px;
  }
}
